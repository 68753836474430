import Lax from 'lax.js';

const initLax = () => {
	const isLandinpage = document.querySelector('.page-template-landingpage');
	const screenWidth = window.innerWidth;
	const isAboutPage = document.querySelector('.page-template-about');

	window.onload = function () {
		lax.init()

		lax.addDriver('scrollY', function () {
			const scrollY = isLandinpage || screenWidth < 768 ? window.scrollY : document.querySelector('.site-main').scrollLeft;

			return scrollY;
		});

		lax.addElements('.section--entry .section__headline', {
			scrollY: {
				scale: [
					[0, 1000],
					{
						768: [1, 1],
						9999: [1, 1.75]
					}
				],
				opacity: [
					['elCenterX', 'elOutX'],
					{
						768: [1, 1],
						9999: [1, -1]
					}
				]
			}
		});

		lax.addElements('.section--service .section__headline', {
			scrollY: {
				scale: [
					['elInY', 'elOutY'],
					{
						768:[1, 1],
						9999:[.925, 1.075]
					}
				],
				opacity: [
					['elInY', 'elCenterY'],
					{
						768:[1, 1],
						9999: [0, 1]
					}
				]
			}
		});

		lax.addElements('.section--perfektionist .section__headline, .section--booking .section__headline', {
			scrollY: {
				translateX: [
					['elInY', 'elCenterY', 'elOutY'],
					{
						768:[0, 0, 0],
						9999: [10, 0, -10]
					}
				],
				opacity: [
					['elInY', 'elCenterY', 'elOutY'],
					{
						768:[1, 1, 1],
						9999: [0, 1, 1]
					}
				]
			}
		});

		lax.addElements('.section--perfektionist .section__text, .section--booking .section__text', {
			scrollY: {
				translateX: [
					['elInY', 'elCenterY', 'elOutY'],
					{
						768: [0, 0, 0],
						9999: [-10, 0, 10]
					}
				],
				opacity: [
					['elInY', 'elCenterY', 'elOutY'],
					{
						768:[1, 1, 1],
						9999: [0, 1, 1]
					}
				]
			}
		});

		lax.addElements('.lax_parallax-image', {
			scrollY: {
				translateY: [
					['elInY', 'elCenterX', 'elOutY'],
					{
						768: [0, 0, 0],
						9999: [-400, 0, 400]
					}
				]
			}
		});

		lax.addElements('.section--one .section__headline', {
			scrollY: {
				scale: [
					['elCenterX', 'elOutX'],
					{
						768: [1, 1, 1],
						9999: [1, 5]
					}
				],
				opacity: [
					[0, 1000],
					{
						768: [1, 1, 1],
						9999: [1, 0]
					}
				]
			}
		});

		lax.addElements('.section--one .section__text', {
			scrollY: {
				translateX: [
					['elInX', 'elCenterX', 'elOutX'],
					{
						768: [0, 0, 0],
						9999: [200, 0, 100]
					}
				],
				opacity: [
					[0, 1000],
					{
						768: [1, 1, 1],
						9999: [1, 0]
					}
				]
			}
		});

		// lax.addElements('.section--two .section__headline', {
		// 	scrollY: {
		// 		translateX: [
		// 			['elInX', 'elOutX'],
		// 			{
		// 				768: [0, 0, 0],
		// 				9999: [-50, 100]
		// 			}
		// 		],
		// 	}
		// });

		lax.addElements('.section--three .section__image', {
			scrollY: {
				scale: [
					['elInX', 'elCenterX'],
					{
						768: [1, 1, 1],
						9999: [.8, 1]
					}
				],
			}
		});

		lax.addElements('.section--three .section__image img', {
			scrollY: {
				translateX: [
					['elInX', 'elCenterX', 'elOutX'],
					{
						768: [0, 0, 0],
						9999: [-100, 0, 100]
					}
				],
			}
		});

		lax.addElements('.section--product .navigation', {
			scrollY: {
				scale: [
					['elInX', 'elCenterX'],
					{
						768: [1, 1, 1],
						9999: [.8, 1]
					}
				],
			}
		});

		lax.addElements('.section--product .teaser-image__img', {
			scrollY: {
				translateX: [
					['elInX', 'elCenterX'],
					{
						768: [0, 0, 0],
						9999: [-200, 0]
					}
				],
				scale: [
					['elInX', 'elCenterX'],
					{
						768: [1, 1, 1],
						9999: [.8, 1]
					}
				],
			}
		});

		lax.addElements('.section--gallery img', {
			scrollY: {
				translateX: [
					['elInX', 'elCenterX', 'elOutX'],
					{
						768: [0, 0, 0],
						9999: [100, 0, -100]
					}
				],
				opacity: [
					['elInX', 'elCenterX', 'elOutX'],
					{
						768: [1, 1, 1],
						9999: [-.5, 1.5, .25]
					}
				],
			}
		});
	}
}

export default initLax;
