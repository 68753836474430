import Swiper from 'swiper/bundle';
import initLax from './lax.js';


initLax();

window.addEventListener('resize', () => {
	initLax();
});

const initToggles = ()  => {
	const toggles = document.querySelectorAll('.js-toggle');

	const closeToggle = ($toggle) => {
		const $target = document.querySelector($toggle.getAttribute('data-target'));
		$toggle.setAttribute('aria-expanded', 'false');
		$target.classList.remove('show');
		document.body.classList.remove('body-scroll-lock');
		document.body.classList.remove('navbar-expanded');
	}

	toggles && toggles.forEach((toggle) => {
		const $target = document.querySelector(toggle.getAttribute('data-target'));
		const $links = $target.querySelectorAll('.nav-link');

		toggle.addEventListener('click', () => {
			const isOpen = toggle.getAttribute('aria-expanded') === 'true';
			toggle.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
			$target.classList.toggle('show');
			document.body.classList.toggle('body-scroll-lock');
			document.body.classList.toggle('navbar-expanded');

			if (!isOpen) {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		});

		$links.forEach(($link) => {
			$link.addEventListener('click', () => {
				closeToggle(toggle);
			});
		});
	});
};

initToggles();

const swiper = new Swiper('.swiper--cover', {
	allowTouchMove: false,
  speed: 3000,
  autoplay: {
    delay: 1000,
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  // Enable lazy loading
  lazy: {
		loadPrevNext: true
	},
});

const productSlider = new Swiper('.swiper--products', {
  slidesPerView: 1.5,
  spaceBetween: 32,
  centeredSlides: true,
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 2000,
    pauseOnMouseEnter: false,
  },
	breakpoints: {
    768: {
      slidesPerView: 3.5,
    }
  }
});

/**
 * Ajax add to cart
 */
jQuery(document).ready(function ($) {
  $('.single_add_to_cart_button').on('click', function (e) {
    e.preventDefault();

    var $thisbutton = $(this),
      $form = $thisbutton.closest('form.cart'),
      id = $thisbutton.val(),
      product_qty = $form.find('input[name=quantity]').val() || 1,
      product_id = $form.find('input[name=product_id]').val() || id,
      variation_id = $form.find('input[name=variation_id]').val() || 0;

    var data = {
      action: 'ql_woocommerce_ajax_add_to_cart',
      product_id: product_id,
      product_sku: '',
      quantity: product_qty,
      variation_id: variation_id,
    };

    $(document.body).trigger('adding_to_cart', [$thisbutton, data]);

    $.ajax({
      url: wc_add_to_cart_params.ajax_url,
      type: 'POST',
      data: data,
      beforeSend: function (response) {
        $thisbutton.removeClass('added').addClass('loading');
      },
      complete: function (response) {
        $thisbutton.addClass('added').removeClass('loading');

				const $productCounter = document.querySelectorAll('.xoo-wsc-modal')[0];
				$productCounter.classList.add('show');
      },
      success: function (response) {
        if (response.error && response.product_url) {
          window.location = response.product_url;
          return;
        } else {
					// console.log('success', response)
          $(document.body).trigger('added_to_cart', [
            response.fragments,
            response.cart_hash,
            $thisbutton,
          ]);
        }
      },
    });

    return false;
  });
});

/**
 * Show cart button when click add to cart button
 */
// const $deleteButtons = document.querySelectorAll('.xoo-wsc-smr-del');

// $deleteButtons.forEach(($delBtn) => {
// 	$delBtn.addEventListener('click', () => {
// 		console.log('delete')
// 	});
// });


/**
 * Text marquee
 */
const initMarquee = () => {
	const marquees = document.querySelectorAll('.js-marquee');

	const calc = () => {
		if (marquees) {
			marquees.forEach(($el) => {
				const $inner = $el.querySelector('.js-marquee-inner');
				const $text = $el.querySelector('.js-marquee-text');
				const $textClone = $text.cloneNode(true);

				$inner.style.width = `${$text.offsetWidth}px`;
				$inner.innerHTML = '';
				$inner.appendChild($text);
				$inner.appendChild($textClone);
				$el.classList.add('active');
			});
		}
	}

	calc();

	window.addEventListener('resize', () => {
		calc();
	})
};

initMarquee();


const initAboutPage = () => {
	const scrollContainer = document.querySelector('.page-template-about .site-main');

	const setHorizontalScroll = (evt) => {
		evt.preventDefault();
		scrollContainer.scrollLeft += evt.deltaY;
	}

	const checkMediaQuery = () => {
		if (scrollContainer && window.innerWidth >= 768) {
			scrollContainer.addEventListener("wheel", setHorizontalScroll);
		} else {
			scrollContainer.removeEventListener("wheel", setHorizontalScroll);
			scrollContainer.scrollLeft = 0;
		}
	}

	checkMediaQuery();

	window.addEventListener('resize', () => {
		checkMediaQuery();
	});
}

initAboutPage();
